import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { 
  AppComponent,
  HelpDialogueComponent,
  GiveUpDialogueComponent,
  StatsDialogueComponent,
  BattleDialogueComponent,
  CustomPuzzleDialogueComponent,
  PrivacyDialogueComponent,
  TransferStatsComponent
} from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GtagModule } from 'angular-gtag';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const routes: Routes = [
  {path: '**', component: AppComponent}
];

const config: SocketIoConfig = { url: 'https://symble-multiplayer.herokuapp.com/', options: {} };//{ url: 'http://localhost:3000', options: {} };//

import { MultiplayerService } from './multiplayer.service';
import { AdSharedService } from './venatus.service';

@NgModule({
  declarations: [
    AppComponent,
    HelpDialogueComponent,
    GiveUpDialogueComponent,
    StatsDialogueComponent,
    BattleDialogueComponent,
    CustomPuzzleDialogueComponent,
    PrivacyDialogueComponent,
    TransferStatsComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule,
    MatRadioModule,
    MatDialogModule,
    MatInputModule,
    MatMenuModule,
    MatSnackBarModule,
    GtagModule.forRoot({ trackingId: 'G-7HMCWPDZSX' }),
    SocketIoModule.forRoot(config)
  ],
  providers: [MultiplayerService, AdSharedService],
  bootstrap: [AppComponent]
})
export class AppModule { }
