import { Component, ElementRef, HostListener, Inject, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultiplayerService } from './multiplayer.service';
import { wordList } from './word-list';
import { symbolList } from './symbol-list';
import { colorList } from './color-list';
import { guessList } from './guess-list';
import { Gtag } from 'angular-gtag';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import * as dayjs from 'dayjs';
import * as daysjsPluginRelativeTime from 'dayjs/plugin/relativeTime';
import { AdSharedService } from './venatus.service';
dayjs.extend(daysjsPluginRelativeTime)

@Component({
  selector: 'help-dialog',
  templateUrl: 'help-dialog.html',
  styleUrls: ['./help-dialog.scss']
})
export class HelpDialogueComponent {}

@Component({
  selector: 'privacy-dialog',
  templateUrl: 'privacy-dialog.html',
  styleUrls: ['./privacy-dialog.scss']
})
export class PrivacyDialogueComponent {}


@Component({
  selector: 'giveup-dialog',
  templateUrl: 'giveup-dialog.html',
  styleUrls: ['./giveup-dialog.scss']
})
export class GiveUpDialogueComponent {}

@Component({
  selector: 'stats-dialog',
  templateUrl: 'stats-dialog.html',
  styleUrls: ['./stats-dialog.scss']
})
export class StatsDialogueComponent {
  total = 0;
  avg: (number | string) = 0;
  winRate: (number | string) = 0;
  barWidths = [];
  barColors = [];
  timeUntilDaily = dayjs(dayjs().format().split('T')[0]).add(1,'day').fromNow();

  blitzPbScore;
  blitzPbAvg;
  blitzLastScore;
  blitzLastAvg;
  newBlitzPb = false;
  battleWins;
  battleRate;
  opponentAvgScore;
  opponentScore;
  missedAnswer = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _snackBar: MatSnackBar,
    private gtag: Gtag
  ) {
    if(this.data.mode === 'daily' || this.data.mode === 'infinite' || this.data.mode === 'custom'){
      this.total = this.data.stats.scoreFreqs.reduce((a,b) => a+b, 0);
      const totalWiningScores = this.data.stats.scoreFreqs.slice(0,-1).map((x,i) => x*(i+1)).reduce((a,b) => a+b, 0);
      const totalWinningGames = this.total - this.data.stats.scoreFreqs[8];
      this.avg = totalWinningGames ? Math.round(totalWiningScores * 100 / totalWinningGames) / 100 : 'N/A';
      this.winRate = this.total ? Math.round(totalWinningGames * 100 / this.total) : 'N/A';

      const max = Math.max(...this.data.stats.scoreFreqs);
      this.data.stats.scoreFreqs.forEach(x => {
        let saturation = (100 * x / max);
        this.barColors.push(`hsla(120, ${saturation || 0}%, 40%, 1)`);

        let width = (94 * x / max);
        this.barWidths.push((width || 5) + '%');
      });
    }
    else {
      this.blitzPbScore = this.data.stats.maxScore || 0;
      this.blitzPbAvg = Math.round(this.data.stats.avgScore * 100) / 100 || 'N/A';
      this.blitzLastScore = this.data.results.maxScore || 0;
      this.blitzLastAvg = (Math.round(this.data.results.avgScore * 100) / 100) || 'N/A';
      this.newBlitzPb = this.blitzLastScore === this.blitzPbScore && this.blitzLastAvg === this.blitzPbAvg;
      this.battleWins = this.data.stats.battleWins || 0;
      let winRate = Math.round(this.data.stats.battleWins / this.data.stats.battleGames * 100);
      this.battleRate = winRate ? winRate + '%' : 'N/A';
      this.opponentScore = data.results.opponentScore || 0;
      this.opponentAvgScore = (Math.round((data.results.opponentAvgScore || 0) * 100) / 100) || 'N/A';
      this.missedAnswer = (data.results.missedAnswer || '').toUpperCase();
    }
  }

  shareResults = () => {
    this.gtag.event('share', this.data.mode);
    const colorKey = {
      '#e81224': '🟥',
      '#f7630c': '🟧',
      '#fff100': '🟨',
      '#16c60c': '🟩',
      '#0078d7': '🟦',
      '#886ce4': '🟪',
      '#404040': '⬛'
    }

    if(this.data.results && this.data.results.results && (this.data.mode === 'daily' || this.data.mode === 'infinite' || this.data.mode === 'custom')){
      let output = `${this.data.mode === 'daily' ? 'Daily ' : ''}Symble ${this.data.mode === 'daily' ? ('#' + this.data.dailyPuzzleNumber + ' ') : ''}${this.data.results.winner ? this.data.results.results.length : 'X'}/8\n`;

      if(this.data.mode === 'custom'){
        output = `Custom Symble ${this.data.results.winner ? this.data.results.results.length : 'X'}/8\n`;
      }
      this.data.results.results.forEach(r => {
        output = output + r.wordleInfo.map(i => colorKey[this.data.key[i].wordleColor.hex]).join('') + '\n';
      })

      if(this.data.mode === 'custom'){
        output += `Try this custom Symble at https://www.symble.app${this.data.results.customUrl} !\n`;
      }
      else {
        output += 'Play on https://www.symble.app !\n';
      }

      navigator.clipboard.writeText(output);
      this._snackBar.open(
        'Most recent ' + this.data.mode + ' results copied to clipboard!',
        '',
        {
          duration: 2000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
    }
    else if(this.data.results && this.data.mode === 'blitz' && this.data.results.maxScore > 0){
      let output = `I solved ${this.data.results.maxScore} words in Symble 5 Minute Blitz with ${this.blitzLastAvg} average guesses per word!\n`;
      output += 'Try to beat me on https://www.symble.app !\n';
      navigator.clipboard.writeText(output);
      this._snackBar.open(
        'Most recent ' + this.data.mode + ' results copied to clipboard!',
        '',
        {
          duration: 2000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
    }
    else if(this.data.results && this.data.mode === 'blitz' && this.data.results.maxScore === 0) {
      this._snackBar.open(
        "Don't share that one, do better 🤣",
        '',
        {
          duration: 2000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
    }
    else if(this.data.mode === 'battle' && this.data.stats.battleGames !== 0){
      let output = `I've won ${this.battleWins} Live Symble Battles so far, ${this.battleRate} of every game!\nTry to take me down on https://www.symble.app !\n`
      navigator.clipboard.writeText(output);
      this._snackBar.open(
        'Most recent ' + this.data.mode + ' results copied to clipboard!',
        '',
        {
          duration: 2000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
    }
    else {
      this._snackBar.open(
        'Finish a game first!',
        '',
        {
          duration: 2000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
    }
  }
  navToSupport = () => {
    this.gtag.event('donate');
    window.open("https://www.buymeacoffee.com/dannyb21892", "_blank");
  }
}

@Component({
  selector: 'battle-dialog',
  templateUrl: 'battle-dialog.html',
  styleUrls: ['./battle-dialog.scss']
})
export class BattleDialogueComponent {
  roomName = '';
}

@Component({
  selector: 'custom-dialog',
  templateUrl: 'custom-dialog.html',
  styleUrls: ['./custom-dialog.scss']
})
export class CustomPuzzleDialogueComponent {
  customWord = '';
  invalid = true;

  inputInvalid = (customWord) => {
    this.invalid = this.customWord.length !== 5 ||
      !!this.customWord.toUpperCase().split('')
      .find(char => char.charCodeAt(0) < 65 || char.charCodeAt(0) > 90);
  }
}

@Component({
  selector: 'transfer-stats',
  templateUrl: 'transfer-stats.html',
  styleUrls: ['./transfer-stats.scss']
})
export class TransferStatsComponent {
  importExport = 'export';
  importData = '';
  inputValid = false;

  isInputValid = (event) => {
    try {
      let testData = JSON.parse(event); 
      if(typeof testData !== 'object'){
        this.inputValid = false;
        throw 'Invalid input';
      }
      let valid = true;
      Object.entries(testData).forEach(([key,v]) => {
        let val = JSON.parse(v as string);
        if(key === 'stats' || key === 'statscustom'){
          valid = valid &&
                  this.valueIsValidNumber(val['streak']) &&
                  this.valueIsValidNumber(val['maxStreak']) &&
                  val['maxStreak'] >= val['streak'] &&
                  Array.isArray(val['scoreFreqs']) &&
                  val['scoreFreqs'].length === 9 &&
                  val['scoreFreqs'].every(scoreFreq => this.valueIsValidNumber(scoreFreq)) &&
                  val['maxStreak'] <= val['scoreFreqs'].reduce((a,b) => a+b, 0);
        }
        else if(key === 'blitzStats'){
          valid = valid &&
                  this.valueIsValidNumber(val['maxScore']) &&
                  this.valueIsValidNumber(val['avgScore'], false) &&
                  this.valueIsValidNumber(val['battleWins']) &&
                  this.valueIsValidNumber(val['battleGames']) && 
                  val['battleGames'] >= val['battleWins'];
        }
      });
      if(!valid) throw 'Invalid input';
      this.inputValid = true;
    }
    catch(error){
      this.inputValid = false;
    }
  }

  valueIsValidNumber = (testNumber, assertInteger = true) => 
    typeof testNumber === 'number' &&
    testNumber >= 0 &&
    (assertInteger ? Math.floor(testNumber) === testNumber : true);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  version = 'v2.2.3';
  title = 'Symble';

  answer = '';
  symbols = '';
  key = {}
  guess = '';
  info = [];
  allWordList = [...wordList, ...guessList];
  gameOver = false;
  winner = false;
  mode = 'daily';
  dailyPuzzleNumber = 0;

  guesses = [];
  gameLength = 8;
  currentGuessNumber = 0;
  currentlyRevealing = false;
  showWordleColors = false;

  showWordleColorsTimer;
  clickTimer = false;
  showStatsTimer;
  endGameTimer;
  dialogOpen = false;

  keyboard = [];

  gameStateOnPageLoad = null;

  defaultStats = '{"streak": 0, "maxStreak": 0, "scoreFreqs": [0,0,0,0,0,0,0,0,0]}';
  defaultBlitzStats = '{"maxScore": 0, "avgScore": 0, "battleWins": 0, "battleGames": 0}';

  headerClickCount = 0;

  blitzStart;
  blitzEndTimeout;
  blitzTimerInterval;
  blitzFinishCount = 0;
  blitzAvgScore = 0;
  roundedBlitzAvgScore;
  blitzDisplayTimer;
  flashBlitzScore = false;

  waitingForOpponent = false;
  opponentFound = false;
  opponentScore = 0;
  opponentAvgScore = 0;
  flashOpponentScore = false;
  waitingForMultiplayerResponse = false;
  battleMessage = '';
  battleSubscriptions = [];

  customAnswerDecoded;

  mobileDesktopOrFullView: 'mobile' | 'desktop' | 'full' = 'desktop';
  pixelRatio = 1;

  @ViewChildren('ad') adAnchors: QueryList<ElementRef>;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private gtag: Gtag,
    private multiplayer: MultiplayerService,
    private location: Location,
    private renderer: Renderer2,
    private el: ElementRef,
    private AdSharedService: AdSharedService,
  ) {
    const start = dayjs("2022-03-21");
    const end = dayjs(new Date());
    const diff = end.diff(start, "days");
    this.dailyPuzzleNumber = diff;
    
    this.setVhCssVariableBecauseAppleSucks(false);
    window.addEventListener('resize', _.debounce(this.setVhCssVariableBecauseAppleSucks, 100));
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1000); //re-evaluate css variable when fully loaded

    this.gameStateOnPageLoad = {
      daily: JSON.parse(localStorage.getItem('dailyGameState')),
      infinite: JSON.parse(localStorage.getItem('infiniteGameState'))
    }

    if(this.location.path() === ''){
      this.mode = localStorage.getItem('mode') || 'daily';
      this.startOver(true);
      if(this.mode === 'custom'){
        this.setCustomWord();
      }
    }

    if(!localStorage.getItem('returningUser')){
      this.showHelp();
      localStorage.setItem('returningUser', 'true');
    }

    if(localStorage.getItem('battle-active') === 'true'){
      localStorage.setItem('battle-active', 'false');
      let stats = JSON.parse(localStorage.getItem('blitzStats') || this.defaultBlitzStats);
      stats.battleGames += 1;
      localStorage.setItem('blitzStats', JSON.stringify(stats));
    }

    this.onUrlChange(this.location.path())
    this.location.onUrlChange(this.onUrlChange);

    this.multiplayer.disconnectSubject.subscribe(() => {
      this.waitingForOpponent = false;
      this.opponentFound = false;
      this.waitingForMultiplayerResponse = false;
      this.unsubFromBattle();
    });
  }

  onUrlChange = (url) => {
    let params = url.split('/').slice(1); //get rid of forward slash at the start
    if(params[0] && Number(params[0]) && params[0].length === 16){
      if(params.length > 1){
        this.location.go('/' + params[0])
      }
      else {
        this.mode = 'custom';
        localStorage.setItem('mode', 'custom');
        this.gameOver = false;
        this.decodeCustomWord(params[0]);
      }
    }
    else if(params[0]){
      this.location.go('');
      this.mode = 'daily';
      localStorage.setItem('mode', 'daily');
      this.startOver(true);
    }
  }

  ngAfterViewInit() {
    // This method is called once the component is initialized
    this.initAds();
  }

  ngOnDestroy() {
    // This method is called just before the component is destroyed
    this.destroyAds();
  }

  initAds = () => {
    this.AdSharedService.OnInit(this.adAnchors, this.renderer);
  }

  destroyAds = () => {
    this.AdSharedService.onDestroy(this.el);
  }

  resetAds = () => {
    this.destroyAds();
    setTimeout(this.initAds);
  }

  openPrivacyPolicy = () => {
    const dialogRef = this.dialog.open(PrivacyDialogueComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
    });
    this.dialogOpen = true;
    dialogRef.afterClosed().subscribe(() => this.dialogOpen = false);
  }

  setMode = (mode) => {
    if(mode !== this.mode){
      this.location.go('');//ensure no custom word when changing modes
      if(this.mode === 'blitz'){
        if(this.blitzStart){
          this.giveUp();
        }
      }
      else if(this.mode === 'battle'){
        if(this.blitzStart){
          this.giveUp();
        }
        this.unsubFromBattle();
        this.multiplayer.disconnect();
      }
      this._snackBar.open(
        `Now playing ${mode === 'daily' ? `Daily Puzzle #${this.dailyPuzzleNumber}!` : (mode === 'infinite' ? 'in Infinite Mode!' : (mode === 'blitz' ? '5 Minute Blitz!' : (mode === 'battle' ? 'Live Blitz Battle!' : 'Custom Puzzle Mode!')))}`,
        '',
        {
          duration: 2000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
      clearTimeout(this.endGameTimer);
      this.customAnswerDecoded = false;
      this.endGameTimer = null;
      this.saveOrClearGameOnExit();
      this.mode = mode;
      localStorage.setItem('mode', this.mode);
      if(this.mode === 'daily' || this.mode === 'infinite'){
        this.startOver(true);
      }
      else {
        clearTimeout(this.showStatsTimer);
        this.showStatsTimer = null;
        clearTimeout(this.showWordleColorsTimer);
        this.showWordleColorsTimer = null;
        this.showWordleColors = false;
        this.gameOver = false;
        this.winner = false;
        this.clearOldGame();
      }
      if(mode === 'custom'){
        this.setCustomWord();
      }
    }
  }

  setVhCssVariableBecauseAppleSucks = (fromResize = true) => {
    this.pixelRatio = (window.devicePixelRatio === 1.5 ? 1.5 : 1);
    document.documentElement.style.setProperty('zoom',`${1 / this.pixelRatio}`);
    document.documentElement.style.setProperty('--dpr',`${this.pixelRatio}`);
    let fullCutoff = 1300 / this.pixelRatio;
    let mobileCutoff = 728 / this.pixelRatio;

    // First we get the viewport height and we multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh * this.pixelRatio}px`);
    let oldView = this.mobileDesktopOrFullView;
    this.mobileDesktopOrFullView = window.innerWidth > fullCutoff ? 'full' : (window.innerWidth > mobileCutoff ? 'desktop' : 'mobile');
    if(oldView !== this.mobileDesktopOrFullView && fromResize){
      this.resetAds();
    }
  }

  prepareBattle = () => {
    const dialogRef = this.dialog.open(BattleDialogueComponent, {
      maxWidth: '80%',
      maxHeight: '40%',
      disableClose: true //prevents closing by clicking outside or using escape key
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(typeof result === 'string'){
        this.initMultiplayer(result)
      }
    });
  }

  initMultiplayer = (roomName = '') => {
    this.battleMessage = '';
    this.multiplayer.connect(roomName);
    this.battleSubscriptions.push(
        this.multiplayer.waitingForOpponent().pipe(first()).subscribe(() => {
        this.waitingForOpponent = true;
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.opponentFound().pipe(first()).subscribe(() => {
        this.waitingForOpponent = false;
        this.opponentFound = true;
        this.opponentScore = 0;
        this.opponentAvgScore = 0;
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.gameStart().pipe(first()).subscribe((answerIndex) => {
        this.opponentFound = false;
        this.startBlitz(answerIndex);
        localStorage.setItem('battle-active', 'true')
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.opponentDisconnected().pipe(first()).subscribe((gameState: any) => {
        if(this.blitzStart){
          this.endBattle(gameState, true);
        }
        else {
          this.opponentFound = false;
          this.multiplayer.disconnect();
          this._snackBar.open(
            `Opponent disconnected before the game begun!`,
            '',
            {
              duration: 2000,
              panelClass: 'clipboard-notification',
              verticalPosition: 'top'
            }
          );
        }
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.incorrect().subscribe((gameState: any) => {
        this.waitingForMultiplayerResponse = false;
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.correct().subscribe((gameState: any) => {
        this.waitingForMultiplayerResponse = false;
        this.blitzAvgScore = gameState.game[gameState.player].avgGuesses;
        this.blitzFinishCount = gameState.game[gameState.player].score;
        this.progressBlitz(gameState.answer);
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.opponentCorrect().subscribe((gameState: any) => {
        this.opponentAvgScore = gameState.game[gameState.player].avgGuesses;
        this.opponentScore = gameState.game[gameState.player].score;
        this.flashOpponentScore = true;
        setTimeout(() => this.flashOpponentScore = false, 1000);
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.win().pipe(first()).subscribe((gameState: any) => {
        this.waitingForMultiplayerResponse = false;
        this.gtag.event('end_game', {'winner': 'true'});
        this.endBattle(gameState, true);
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.lose().pipe(first()).subscribe((gameState: any) => {
        this.waitingForMultiplayerResponse = false;
        this.gtag.event('end_game', {'winner': 'false'});
        this.endBattle(gameState, false);
      })
    );
    this.battleSubscriptions.push(
      this.multiplayer.tie().pipe(first()).subscribe((gameState: any) => {
        this.waitingForMultiplayerResponse = false;
        this.gtag.event('end_game', {'winner': 'true'});
        this.endBattle(gameState, true);
      })
    );
  }

  unsubFromBattle = () => {
    this.battleSubscriptions.forEach(sub => sub.unsubscribe());
    this.battleSubscriptions = [];
  };

  endBattle = (gameState, winner) => {
    this.unsubFromBattle();
    this.multiplayer.disconnect();
    this.gameOver = true;
    this.winner = winner;
    this.battleMessage = gameState.outcome;
    clearInterval(this.blitzTimerInterval);
    this.roundedBlitzAvgScore = Math.round(this.blitzAvgScore * 100) / 100;
    let stats = JSON.parse(localStorage.getItem('blitzStats') || this.defaultBlitzStats);
    stats.avgScore = this.blitzFinishCount > stats.maxScore ?
                     this.blitzAvgScore :
                     (
                       this.blitzFinishCount === stats.maxScore ?
                       Math.min(this.blitzAvgScore, stats.avgScore) :
                       stats.avgScore
                     );
    stats.maxScore = Math.max(this.blitzFinishCount, stats.maxScore);
    stats.battleWins = stats.battleWins + (winner ? 1 : 0);
    stats.battleGames += 1;
    localStorage.setItem('blitzStats', JSON.stringify(stats));
    localStorage.setItem('lastResultsbattle', JSON.stringify({
      maxScore: this.blitzFinishCount,
      avgScore: this.blitzAvgScore,
      opponentScore: this.opponentScore,
      opponentAvgScore: this.opponentAvgScore,
      isBattle: true,
      missedAnswer: this.answer
    }));
    this.showStatsTimer = setTimeout(this.showStats, 1500);
    this.blitzStart = null;
    localStorage.setItem('battle-active', 'false')
  }

  startBlitz = (answerIndex = null) => {
    this.blitzStart = Date.now();
    if(this.mode === 'blitz'){//not battle
      this.blitzEndTimeout = setTimeout(() => this.endBlitz(true), 300000);//5min
    }

    let blitzTimer = 300;
    this.blitzDisplayTimer = '5:00';
    this.blitzTimerInterval = setInterval(() => {
      blitzTimer = blitzTimer <= 0 ? 0 : (blitzTimer - 1);
      this.blitzDisplayTimer = Math.floor(blitzTimer / 60) + ':' + ((blitzTimer % 60 < 10 ? '0' : '') + blitzTimer % 60);
    }, 1000);

    this.blitzFinishCount = 0;
    this.blitzAvgScore = 0;
    this.gtag.event('new_game', {'mode': this.mode});
    this.gameOver = false;
    this.winner = false;
    this.initNewGame(answerIndex);
  }

  progressBlitz = (answerIndex = null) => {
    if(this.mode === 'blitz'){
      this.blitzAvgScore = ((this.blitzAvgScore * this.blitzFinishCount) + this.info.length) / ++this.blitzFinishCount;
    }
    this.flashBlitzScore = true;
    setTimeout(() => this.flashBlitzScore = false, 1000);
    this.initNewGame(answerIndex);
  }

  endBlitz = (timerEnding = false) => {
    let blitzEnd = Date.now();
    this.gameOver = true;
    this.roundedBlitzAvgScore = Math.round(this.blitzAvgScore * 100) / 100;
    clearTimeout(this.blitzEndTimeout);
    clearInterval(this.blitzTimerInterval);
    if(timerEnding && Math.abs(blitzEnd - this.blitzStart - 300000) <= 1000){//game ended between 4:59 - 5:01
      this.winner = true;

      let stats = JSON.parse(localStorage.getItem('blitzStats') || this.defaultBlitzStats);
      stats.avgScore = this.blitzFinishCount > stats.maxScore ?
                       this.blitzAvgScore :
                       (
                         this.blitzFinishCount === stats.maxScore ?
                         Math.min(this.blitzAvgScore, stats.avgScore) :
                         stats.avgScore
                       );
      stats.maxScore = Math.max(this.blitzFinishCount, stats.maxScore);
      localStorage.setItem('blitzStats', JSON.stringify(stats));
      localStorage.setItem('lastResultsblitz', JSON.stringify({maxScore: this.blitzFinishCount, avgScore: this.blitzAvgScore}));
      this.showStatsTimer = setTimeout(this.showStats, 1500);
    }
    else if(timerEnding){//invalid game
      this._snackBar.open(
        `Timer anomaly detected, results don't count!`,
        '',
        {
          duration: 2000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
    }
    else {//give up or mode change
      //null
    }
    this.blitzStart = null;
  }

  startOver = (checkForExistingGame = false, customAnswer = '') => {
    clearTimeout(this.showStatsTimer);
    this.showStatsTimer = null;
    clearTimeout(this.showWordleColorsTimer);
    this.showWordleColorsTimer = null;
    this.showWordleColors = false;
    this.gameOver = false;
    this.winner = false;
    this.customAnswerDecoded = false;

    let existingGame = JSON.parse(localStorage.getItem(this.mode+'GameState'));
    if(checkForExistingGame && existingGame){
      this.restoreState(existingGame);
    }
    else {
      this.gtag.event('new_game', {'mode': this.mode});
      if(this.mode === 'custom'){
        this.initCustomGame(customAnswer);
      }
      else {
        this.initNewGame();
      }
    }
  }

  initNewGame = (answerIndex = null) => {
    this.clearOldGame();
    this.chooseRandomWord(answerIndex);
    this.chooseSymbols();
  }

  clearOldGame = () => {
    this.answer = '';
    this.symbols = '';
    this.key = {}
    this.guess = '';
    this.info = [];
    this.guesses = [];
    this.battleMessage = '';
    for(let i = 0; i < this.gameLength; i++){
      this.guesses.push(Array(5).fill(''))
    }
    this.currentGuessNumber = 0;
    this.keyboard =
      [
        [
          { letter: 'q', used: false },
          { letter: 'w', used: false },
          { letter: 'e', used: false },
          { letter: 'r', used: false },
          { letter: 't', used: false },
          { letter: 'y', used: false },
          { letter: 'u', used: false },
          { letter: 'i', used: false },
          { letter: 'o', used: false },
          { letter: 'p', used: false },
        ],
        [
          { letter: null, used: false},
          { letter: 'a', used: false },
          { letter: 's', used: false },
          { letter: 'd', used: false },
          { letter: 'f', used: false },
          { letter: 'g', used: false },
          { letter: 'h', used: false },
          { letter: 'j', used: false },
          { letter: 'k', used: false },
          { letter: 'l', used: false },
          { letter: null, used: false},
        ],
        [
          { letter: null, used: false},
          { letter: '<img src="assets/symbols/subdirectory_arrow_right_white.svg">', used: true },
          { letter: 'z', used: false },
          { letter: 'x', used: false },
          { letter: 'c', used: false },
          { letter: 'v', used: false },
          { letter: 'b', used: false },
          { letter: 'n', used: false },
          { letter: 'm', used: false },
          { letter: '<img src="assets/symbols/backspace_white.svg">', used: true },
          { letter: null, used: false},
        ]
      ];
  }

  initCustomGame = (answer) => {
    this.clearOldGame();
    this.answer = answer;
    this.chooseSymbols();
  }

  setCustomWord = () => {
    this.dialogOpen = true;
    const dialogRef = this.dialog.open(CustomPuzzleDialogueComponent, {
      maxWidth: '80%',
      maxHeight: '40%',
      disableClose: true //prevents closing by clicking outside or using escape key
    });

    dialogRef.afterClosed().subscribe((customWord) => {
      if(typeof customWord === 'string'){
        const url = this.encodeCustomURL(customWord);
        navigator.clipboard.writeText('Try to solve my custom Symble puzzle at https://www.symble.app/'+url);
        this._snackBar.open(
          'URL for custom puzzle was copied to clipboard!',
          '',
          {
            duration: 2000,
            panelClass: 'clipboard-notification',
            verticalPosition: 'top'
          }
        );
        this.location.go(url);
        this.startOver(true, customWord.toLowerCase());
        this.customAnswerDecoded = true;
      }
      this.dialogOpen = false;
    });
  }

  encodeCustomURL = (customWord: string) => {
    let charCodeMutiplier = Math.floor(Math.random() * 8) + 2; //2 to 9;
    let url = `${charCodeMutiplier}`;
    customWord.toUpperCase().split('').forEach(letter => {
      url += letter.charCodeAt(0) * charCodeMutiplier;
    })
    return url
  }

  decodeCustomWord = (encodedCustomWord) => {
    let charCodeMutiplier = Number(encodedCustomWord[0]);
    let customWord = '';
    for(let i = 0; i < 5; i++){
      let decodedChar = Number(encodedCustomWord.slice(3*i + 1, 3*i + 4)) / charCodeMutiplier;
      if(decodedChar === Math.floor(decodedChar)){
        customWord += String.fromCharCode(decodedChar);
      }
      else {
        customWord = '';
        break;
      }
    }
    if(customWord && charCodeMutiplier > 1){
      this.startOver(false, customWord.toLowerCase());
      this.customAnswerDecoded = true;
    }
    else {
      this.location.go('');
    }
  }

  chooseRandomWord = (answerIndex = null) => {
    if(this.mode !== 'daily'){
      this.answer = wordList[answerIndex || Math.floor(Math.random() * wordList.length)];
    }
    else if(this.mode === 'daily') {
      const start = dayjs("2022-03-21");
      const end = dayjs(new Date());
      const diff = end.diff(start, "days");
      this.dailyPuzzleNumber = diff;
      let wordIndex = 21892;
      for(let i = 0; i <= diff; i++){
        wordIndex = this.lcg(wordIndex);
      }
      this.answer = wordList[wordIndex];
    }
  }

  lcg = (seed) => (51*seed)%2311;

  chooseSymbols = () => {
    let tempSymbolList = [...symbolList];
    let tempColorList = [...colorList]
    let correctColor = tempColorList.splice(Math.floor(Math.random() * tempColorList.length), 1)[0];
    let misplacedColor = tempColorList.splice(Math.floor(Math.random() * tempColorList.length), 1)[0];
    this.key = {
      correct: {
        symbol: tempSymbolList.splice(Math.floor(Math.random() * tempSymbolList.length), 1)[0],
        symbolColor: correctColor,
        wordleColor: correctColor,
      },
      misplaced: {
        symbol: tempSymbolList.splice(Math.floor(Math.random() * tempSymbolList.length), 1)[0],
        symbolColor: misplacedColor,
        wordleColor: misplacedColor,
      },
      incorrect: {
        symbol: tempSymbolList.splice(Math.floor(Math.random() * tempSymbolList.length), 1)[0],
        symbolColor: tempColorList.splice(Math.floor(Math.random() * tempColorList.length), 1)[0],
        wordleColor: {hex: '#404040'}
      },
    }
  }

  @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
      if(!this.dialogOpen){
        this.handleInput(event);
      }
    }

  keyboardClicked = (key) => {
    if(key.letter){
      this.handleInput({
        keyCode: key.letter === '<img src="assets/symbols/subdirectory_arrow_right_white.svg">' ? 13 : (key.letter === '<img src="assets/symbols/backspace_white.svg">' ? 8 : 65),
        key: key.letter
      } as KeyboardEvent)
    }
  }

  handleInput = (event: KeyboardEvent) => {
    if(
      (
        (['daily','infinite'].includes(this.mode) && !this.gameOver) ||
        this.blitzStart ||
        (this.mode === 'custom' && this.customAnswerDecoded)
      ) && !this.currentlyRevealing && !this.waitingForMultiplayerResponse
    ){
      if(event.keyCode <= 90 && event.keyCode >= 65 && this.guess.length < 5){
        this.guess += event.key.toLowerCase();
        this.guesses[this.currentGuessNumber] = [
          ...this.guess.split(''),
          ...Array(5 - this.guess.length).fill('')
        ];
        this.clickTimer = true;
        setTimeout(() => this.clickTimer = false, 50)
        this.saveState();
      }
      else if(event.keyCode === 13 && this.guess.length === 5){
        if(!this.allWordList.includes(this.guess) && this.mode !== 'custom'){//custom mode can make any guess and use any answer
          this._snackBar.open(
            `${this.guess.toUpperCase()} not in word list!`,
            '',
            {
              duration: 2000,
              panelClass: 'clipboard-notification',
              verticalPosition: 'top'
            }
          );
        }
        else {
          this.currentGuessNumber += 1;
          this.guessed();
        }
      }
      else if(event.keyCode === 8) {
        this.guess = this.guess.slice(0,-1);
        this.guesses[this.currentGuessNumber] = [
          ...this.guess.split(''),
          ...Array(5 - this.guess.length).fill('')
        ];
        this.saveState();
      }
    }
  }

  guessed = () => {
    let info = {
      guess: this.guess,
      symbolInfo: [],
      wordleInfo: [],
      revealed: []
    };
    let filteredAnswer = [];
    let filteredGuess = [];
    this.answer.split('').forEach((l,i) => {
      if(this.guess[i] === l){
        info.symbolInfo[i] = 'correct';
        filteredAnswer.push('');
        filteredGuess.push('');
      }
      else {
        info.symbolInfo[i] = '';
        filteredAnswer.push(l);
        filteredGuess.push(this.guess[i]);
      }
    });
    filteredAnswer.forEach((l,i) => {
      if(l){
        let misplacedIndex = filteredGuess.findIndex(x => x === l);
        if(misplacedIndex >= 0){
          info.symbolInfo[i] = 'misplaced';
          filteredAnswer[i] = '';
          filteredGuess.splice(misplacedIndex,1,'');
        }
      }
    });

    filteredAnswer = [];
    filteredGuess = [];
    this.guess.split('').forEach((l,i) => {
      if(this.answer[i] === l){
        info.wordleInfo[i] = 'correct';
        filteredAnswer.push('');
        filteredGuess.push('');
      }
      else {
        info.wordleInfo[i] = '';
        filteredGuess.push(l);
        filteredAnswer.push(this.answer[i]);
      }
    });
    filteredGuess.forEach((l,i) => {
      if(l){
        let misplacedIndex = filteredAnswer.findIndex(x => x === l);
        if(misplacedIndex >= 0){
          info.wordleInfo[i] = 'misplaced';
          filteredGuess[i] = '';
          filteredAnswer.splice(misplacedIndex,1,'');
        }
      }
    });
    info.symbolInfo.forEach((x,i) => info.symbolInfo[i] = (x ? x : 'incorrect'));
    info.wordleInfo.forEach((x,i) => info.wordleInfo[i] = (x ? x : 'incorrect'));
    this.info.push(info);

    if(['daily','infinite','custom'].includes(this.mode)){
      this.currentlyRevealing = true;
      setTimeout(() => this.currentlyRevealing = false, 1250);
      for(let i = 0; i <= 4; i++){
        setTimeout(() => info.revealed.push(true), i*300);
      }
    }
    else {
      info.revealed = [true,true,true,true,true]
    }


    this.keyboard.forEach(row => {
      row.forEach(key => {
        key.used = key.used || this.guess.includes(key.letter);
      })
    })

    if(this.mode !== 'battle'){
      if(this.guess === this.answer) {
        if(this.mode !== 'blitz') {
          this.gtag.event('end_game', {'winner': 'true'});
        }
        this.endGameTimer = setTimeout(() => {
          this.endGameTimer = null;
          if(this.mode === 'blitz'){
            this.progressBlitz();
          }
          else {
            this.endGame(true)
          }
        }, this.mode === 'blitz' ? 0 : 1250);
      }
      else if(this.currentGuessNumber >= this.gameLength){
        this.gtag.event('end_game', {'winner': 'false'});
        this.endGameTimer = setTimeout(() => {
          this.endGameTimer = null;
          if(this.mode === 'blitz'){
            this.endBlitz();
          }
          else {
            this.endGame(false)
          }
        }, this.mode === 'blitz' ? 0 : 1250);
      }
    }
    else {//battle
      this.waitingForMultiplayerResponse = true;
      this.multiplayer.sendEvent('guess', wordList.findIndex(x => x === this.guess));
    }

    this.guess = '';
    this.saveState();
  }

  giveUp = () => {
    const dialogRef = this.dialog.open(GiveUpDialogueComponent, {
      maxHeight: '40vh',
      maxWidth: '80vw',
      autoFocus: false
    });
    this.dialogOpen = true;
    dialogRef.afterClosed().subscribe((giveUp: boolean) => {
      this.dialogOpen = false;
      if(giveUp){
        if(this.mode === 'daily' || this.mode === 'infinite' || this.mode === 'custom'){
          this.endGame(false);
        }
        else if(this.mode === 'battle'){
          this.multiplayer.giveUp();
        }
        else if(this.mode === 'blitz'){
          this.endBlitz();
        }
      }
    });
  }

  endGame = (winner = true) => {
    if(!this.endGameTimer){
      this.showWordleColorsTimer = setTimeout(() => this.showWordleColors = true, 400);
      this.gameOver = true;
      this.winner = winner;

      let stats = JSON.parse(localStorage.getItem('stats' + (this.mode === 'custom' ? 'custom' : '')) || this.defaultStats);
      stats.scoreFreqs[this.winner ? this.currentGuessNumber - 1 : 8] += 1;
      stats['streak'] = this.winner ? stats['streak'] + 1 : 0;
      stats['maxStreak'] = Math.max(stats['maxStreak'], stats['streak']);
      localStorage.setItem('stats' + (this.mode === 'custom' ? 'custom' : ''), JSON.stringify(stats));

      localStorage.setItem('lastResults'+this.mode, JSON.stringify({
        winner: this.winner,
        results: this.info,
        customUrl: this.mode === 'custom' ? this.location.path() : null
      }));

      this.showStatsTimer = setTimeout(this.showStats, 1500);
      this.saveState();
    }
  }

  showHelp = () => {
    const dialogRef = this.dialog.open(HelpDialogueComponent, {
      height: '80vh',
      width: '80vw',
      autoFocus: false
    });
    this.dialogOpen = true;
    dialogRef.afterClosed().subscribe(() => this.dialogOpen = false);
  }

  showStats = () => {
    clearTimeout(this.showStatsTimer);
    this.showStatsTimer = null;
    if(_.isEqual(this.key, {})){
      this.chooseSymbols();
    }
    const dialogRef = this.dialog.open(StatsDialogueComponent, {
      width: '80vw',
      autoFocus: false,
      panelClass: 'stats-dialog',
      data: {
        stats: (this.mode === 'blitz' || this.mode === 'battle') ?
                (JSON.parse(localStorage.getItem('blitzStats') || this.defaultBlitzStats)) :
                (JSON.parse(localStorage.getItem('stats' + (this.mode === 'custom' ? 'custom' : '')) || this.defaultStats)),
        results: JSON.parse(localStorage.getItem('lastResults'+this.mode)) || {},
        mode: this.mode,
        dailyPuzzleNumber: this.dailyPuzzleNumber,
        key: this.key,
      }
    });
    this.dialogOpen = true;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
    dialogRef.afterClosed().subscribe(() => this.dialogOpen = false);
  }

  saveState = () => {
    if(this.mode === 'daily' || this.mode === 'infinite'){
      const lastAnswerWasCorrect = this.currentGuessNumber > 0 &&
                                   this.answer === this.guesses[this.currentGuessNumber - 1].join('');
      const gameInterruptedBeforeEnd = !this.gameOver &&
        ((this.currentGuessNumber >= this.gameLength) || lastAnswerWasCorrect);

      localStorage.setItem(this.mode+'GameState', JSON.stringify({
        answer: this.answer,
        symbols: this.symbols,
        key: this.key,
        guess: this.guess,
        info: this.info.map(x => ({...x, revealed:[true,true,true,true,true]})),
        guesses: this.guesses,
        currentGuessNumber: this.currentGuessNumber,
        keyboard: this.keyboard,
        gameOver: this.gameOver || gameInterruptedBeforeEnd,
        winner: this.winner || lastAnswerWasCorrect,
        gameInterruptedBeforeEnd: gameInterruptedBeforeEnd,
        version: this.version,
      }))
    }
  }

  saveOrClearGameOnExit = () => {
    if((this.answer && this.mode === 'daily') ||
       (this.answer && this.mode === 'infinite' && !this.gameOver)){
      this.saveState();
    }
    else {
      localStorage.removeItem(this.mode+'GameState')
    }
  }

  restoreState = (state) => {
    //version format is major.medium.minor and a breaking change is anything
    //that increments medium or higher tier. Don't restore state from a game from
    //a tier that doesnt share major.medium at least
    let breakingChangeVersion = state.version ?
      state.version.split('.').slice(0,2).join('.') !== this.version.split('.').slice(0,2).join('.') :
      true;

    if(
      !breakingChangeVersion &&
      (
        (this.mode === 'infinite' && (state.gameInterruptedBeforeEnd || !state.gameOver)) ||
        this.mode === 'daily'
      )
    ){
      Object.entries(state).forEach(([key,val]) => {
        if(key !== 'version') {
          this[key] = val;
        }
      });
    }
    else {
      this.startOver();
    }

    if(state.gameInterruptedBeforeEnd){
      localStorage.setItem(
        this.mode+'GameState',
        JSON.stringify({...state, gameInterruptedBeforeEnd: false})
      );
      this.endGame(state.winner);
    }
    if(this.mode === 'daily'){
      let oldAnswer = this.answer;
      //if daily mode, check if the saved word is different from today's word
      this.chooseRandomWord();
      if(this.answer !== oldAnswer){
        this.startOver();
      }
      else if(this.gameOver){
        this.gameOver = false;
        setTimeout(() => {
          this.gameOver = true;
          this.showWordleColorsTimer = setTimeout(() => this.showWordleColors = true, 400);
        })
        this.showStats();
      }
    }
  }

  incrementHeaderClick = () => {
    this.headerClickCount += 1;
    if(this.headerClickCount % 10 === 0){
      this._snackBar.open(
        this.version,
        '',
        {
          duration: 1000,
          panelClass: 'clipboard-notification',
          verticalPosition: 'top'
        }
      );
    }
  }

  transferStats = () => {
    let statistics = [
      'statscustom',
      'stats',
      'blitzStats'
    ];

    this.dialogOpen = true;
    const dialogRef = this.dialog.open(TransferStatsComponent, {
      maxHeight: '80vh',
      maxWidth: '80vw',
      autoFocus: false,
      data: statistics
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.dialogOpen = false;
      if(data.importExport === 'Import'){
        let imported = JSON.parse(data.importData);
        Object.entries(imported).forEach(([key,val]) => localStorage.setItem(key, val as string));
        this._snackBar.open(
          'Data successfully imported, welcome back!',
          '',
          {
            duration: 3000,
            panelClass: 'clipboard-notification',
            verticalPosition: 'top'
          }
        );
      }
      else if(data.importExport === 'Export'){
        let exportData = {};
        statistics.forEach(s => {
          let stat = localStorage.getItem(s);
          if(stat) exportData[s] = stat;
        });

        navigator.clipboard.writeText(JSON.stringify(exportData));
        this._snackBar.open(
          'Exported data copied to clipboard! Paste + save it somewhere!',
          '',
          {
            duration: 3000,
            panelClass: 'clipboard-notification',
            verticalPosition: 'top'
          }
        );
      }
    });
  }
}
