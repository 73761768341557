<ng-container *ngIf="data.mode === 'daily' || data.mode === 'infinite' || data.mode === 'custom'; else: blitz">
  <div class="header">
    <span class="header-text">{{data.mode === 'custom' ? 'Custom' : 'Standard'}} Symble Statistics</span>
  </div>
  <div class="overview">
    <div class="stat-container">
      <div class="stat-value">{{total}}</div>
      <div class="stat-name">Total<br>Games</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{winRate}}{{winRate !== 'N/A' ? '%' : ''}}</div>
      <div class="stat-name">Win<br>Rate</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{avg}}</div>
      <div class="stat-name">Avg<br>Score</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{data.stats.streak}}</div>
      <div class="stat-name">Current<br>Streak</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{data.stats.maxStreak}}</div>
      <div class="stat-name">Max<br>Streak</div>
    </div>
  </div>
  <div class="header">
    <span class="header-text">Guess Distribution</span>
  </div>
  <div *ngFor="let scoreFreq of data.stats.scoreFreqs; let i = index" class="chart-bar-container">
    <div class="chart-index">
      {{i === 8 ? 'X' : (i+1)}}
    </div>
    <div class="chart-bar" [ngStyle]="{'width': barWidths[i], 'background-color': barColors[i]}">
      {{scoreFreq}}
    </div>
  </div>
  <div class="countdown-and-button-container">
    <div class="countdown">
      <div class="next-daily">Next Daily</div>
      <div class="time-until">{{timeUntilDaily}}</div>
    </div>
    <button mat-flat-button class="share-button" (click)="shareResults()">
      SHARE <img class="share" src="assets/symbols/share.svg">
    </button>
  </div>
  <div class="support-container">
    <button mat-flat-button class="support-button" (click)="navToSupport()">
      <span>SUPPORT</span><span><img class="support-svg" src="assets/buymeacoffee.svg"/></span>
    </button>
  </div>
</ng-container>
<ng-template #blitz>
  <div class="header">
    <span class="header-text">Blitz PB and Battles</span>
  </div>
  <div class="overview">
    <div class="stat-container">
      <div class="stat-value">{{blitzPbScore}}</div>
      <div class="stat-name">Words<br>Solved</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{blitzPbAvg || N/A}}</div>
      <div class="stat-name">Average<br>Guesses</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{battleWins}}</div>
      <div class="stat-name">Battles<br>Won</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{battleRate}}</div>
      <div class="stat-name">Battle<br>Win Rate</div>
    </div>
  </div>
  <div class="header">
    <span class="header-text">Most Recent {{data?.mode === 'battle' ? 'Battle' : 'Blitz'}}{{this.newBlitzPb && blitzLastScore > 0 ? ' - ' : ''}}<span class="new-pb" *ngIf="newBlitzPb && blitzLastScore > 0">New PB!</span></span>
  </div>
  <div class="overview">
    <div class="stat-container">
      <div class="stat-value">{{blitzLastScore}}</div>
      <div class="stat-name">Words<br>Solved</div>
    </div>
    <div class="stat-container">
      <div class="stat-value">{{blitzLastAvg || N/A}}</div>
      <div class="stat-name">Average<br>Guesses</div>
    </div>
    <div *ngIf="data?.mode === 'battle'" class="stat-container">
      <div class="stat-value">{{opponentScore}}</div>
      <div class="stat-name">Opponent<br>Solved</div>
    </div>
    <div *ngIf="data?.mode === 'battle'" class="stat-container">
      <div class="stat-value">{{opponentAvgScore || 'N/A'}}</div>
      <div class="stat-name">Opponent<br>Average</div>
    </div>
  </div>
  <div *ngIf="data?.mode === 'battle' && missedAnswer" class="missed-answer">Missed Answer: {{missedAnswer}}</div>
  <div class="countdown-and-button-container">
    <div class="countdown">
      <div class="next-daily">Next Daily</div>
      <div class="time-until">{{timeUntilDaily}}</div>
    </div>
    <button mat-flat-button class="share-button" (click)="shareResults()">
      SHARE <img class="share" src="assets/symbols/share.svg">
    </button>
  </div>
  <div class="support-container">
    <button mat-flat-button class="support-button" (click)="navToSupport()">
      <span>SUPPORT</span><span><img class="support-svg" src="assets/buymeacoffee.svg"/></span>
    </button>
  </div>
</ng-template>
