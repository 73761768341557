// shared.service.ts
import { Injectable, Renderer2, ElementRef, QueryList } from '@angular/core';

@Injectable()
export class AdSharedService {
  constructor() {}

  OnInit(adElements: QueryList<ElementRef>, renderer: Renderer2) {
    // Destruction logic
    window.__vm_add = window.__vm_add || [];
    //please replace querySelectorAll incase you have a better way of doing the quiry eg. viewchildren query etc

    adElements.forEach((adElement: ElementRef) => {
      const placementId = adElement.nativeElement.getAttribute('placementId') || 'null';
      const isRichMedia = adElement.nativeElement.getAttribute('richMedia') || false;
      const isHybridBanner = adElement.nativeElement.getAttribute('hybridBanner') || false;

      const divElement = renderer.createElement('div');
      renderer.setAttribute(divElement, 'data-id', placementId);
      renderer.setAttribute(divElement, 'class', 'vm-placement');

      if (isRichMedia) {
        renderer.setAttribute(divElement, 'style', `display: none`);
        renderer.setAttribute(divElement, 'ad-type', 'rich-media');
      }

      if (isHybridBanner) {
        renderer.setAttribute(divElement, 'data-display-type', 'hybrid-banner');
        renderer.setAttribute(divElement, 'ad-type', 'hybrid-banner');
      }

      renderer.insertBefore(adElement.nativeElement.parentElement, divElement, adElement.nativeElement);
      window.__vm_add.push(divElement);

      renderer.removeChild(adElement.nativeElement.parentElement, adElement.nativeElement);
    });
  }

  onDestroy(el: ElementRef) {
    window.__vm_remove = window.__vm_remove || [];
    const adElements = el.nativeElement.querySelectorAll('.vm-placement');
    //remove placement one by one
    adElements.forEach((adElement: Element) => {
      const isRichMedia =
        adElement.getAttribute('ad-type') === 'rich-media';

      if (isRichMedia) {
        window.__vm_remove_category = ['richmedia_all'];
      } else {
        window.__vm_remove.push(adElement);
      }
      adElement.parentElement.remove();
    });
  }
}
