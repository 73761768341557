<div class="header">
  <div class="header-sides">
    <button *ngIf="!gameOver && (mode === 'daily' || mode === 'infinite' || mode === 'custom') && currentGuessNumber > 0" mat-stroked-button class="top-left-button give-up" color="warn" (click)="giveUp()">Give up</button>
    <button *ngIf="gameOver && mode === 'infinite'" mat-stroked-button class="top-left-button play-again" (click)="startOver()">Play again</button>
    <button *ngIf="mode === 'blitz' && !blitzStart" mat-stroked-button class="top-left-button play-again" (click)="startBlitz()">Start Blitz</button>
    <button *ngIf="mode === 'blitz' && blitzStart" mat-stroked-button class="top-left-button give-up" color="warn" (click)="giveUp()">Give up</button>
    <button *ngIf="mode === 'battle' && !(blitzStart || waitingForOpponent || opponentFound)" mat-stroked-button class="top-left-button play-again" (click)="prepareBattle()">Start Battle</button>
    <button *ngIf="mode === 'battle' && waitingForOpponent" mat-stroked-button class="top-left-button give-up" (click)="multiplayer.disconnect()">Stop Waiting</button>
    <button *ngIf="mode === 'battle' && blitzStart" mat-stroked-button class="top-left-button give-up" (click)="giveUp()">Give Up</button>
    <button *ngIf="mode === 'custom' && (!customAnswerDecoded || gameOver)" mat-stroked-button class="top-left-button" (click)="setCustomWord()">Custom Word</button>
  </div>
  <div class="title" (click)="incrementHeaderClick()">SYMBLE</div>
  <div class="header-sides">
    <img class="header-icon" 
          [ngClass]="{'mode-disabled': blitzStart || opponentFound || waitingForOpponent}" 
          [matMenuTriggerFor]="menu"
          [src]="'assets/symbols/' + (mode === 'blitz' ? 'timer' : (mode === 'daily' ? 'calendar_today' : (mode === 'infinite' ? 'all_inclusive' : (mode === 'battle' ? 'emoji_events' : 'edit')))) + '_white.svg'">
    <mat-menu #menu="matMenu">
      <button (click)="setMode('daily')" class="mode-button" mat-menu-item>
        <img class="menu-icon" src="assets/symbols/calendar_today_white.svg">Daily Puzzle #{{dailyPuzzleNumber}}
      </button>
      <button (click)="setMode('infinite')" class="mode-button" mat-menu-item>
        <img class="menu-icon" src="assets/symbols/all_inclusive_white.svg">Infinite Mode
      </button>
      <button (click)="setMode('blitz')" class="mode-button" mat-menu-item>
        <img class="menu-icon" src="assets/symbols/timer_white.svg">5 Minute Blitz
      </button>
      <button (click)="setMode('battle')" class="mode-button" mat-menu-item>
        <img class="menu-icon" src="assets/symbols/emoji_events_white.svg">Live Blitz Battle
      </button>
      <button (click)="setMode('custom')" class="mode-button" mat-menu-item>
        <img class="menu-icon" src="assets/symbols/edit_white.svg">Custom Puzzle
      </button>
      <button (click)="showStats()" class="mode-button" mat-menu-item>
        <img class="menu-icon" src='assets/symbols/sort_white.svg'>View Stats
      </button>
      <button (click)="transferStats()" class="mode-button" mat-menu-item>
        <img class="menu-icon" src="assets/symbols/send_to_mobile_white.svg">Transfer Stats
      </button>
      <!-- <div class="privacy-policy" (click)="openPrivacyPolicy()">Privacy Policy</div> -->
    </mat-menu>
    <!-- <img class="header-icon" [ngClass]="{'mode-disabled': blitzStart || opponentFound || waitingForOpponent}" (click)="showStats()" src='assets/symbols/sort_white.svg'> -->
    <img class="header-icon" [ngClass]="{'mode-disabled': blitzStart || opponentFound || waitingForOpponent}" (click)="showHelp()" src='assets/symbols/help_white.svg'>
    <img class="header-icon" [ngClass]="{'mode-disabled': blitzStart || opponentFound || waitingForOpponent}" (click)="openPrivacyPolicy()" src='assets/symbols/policy.svg'>
  </div>
</div>

<div class="tiles-container">
    <div *ngIf="mobileDesktopOrFullView === 'full'" class="full-ad-parent">
      <div #ad placementId="654b534496a6256ebdf129f3" class="ad-anchor"></div>
    </div>
    <div>
      <div *ngFor="let row of guesses; let r=index" class="tile-row-container">
        <div class="tile-row" [ngClass]="{'bottom-border': r < gameLength - 1}">
          <div *ngFor="let letter of row; let l=index"
                [ngClass]="{'current-guess': currentGuessNumber === r && !gameOver && (blitzStart || mode === 'daily' || mode === 'infinite' || (mode === 'custom' && customAnswerDecoded)), 'tile': currentGuessNumber !== r || guess.length - 1 !== l || !clickTimer,'tile-clicked': clickTimer && currentGuessNumber === r && guess.length - 1 === l, 'flipped': gameOver && currentGuessNumber > r && (mode === 'daily' || mode === 'infinite' || mode === 'custom')}"
                [ngStyle]="{'background-color': (currentGuessNumber > r && !showWordleColors) ? 'grey' : 'inherit', 'borderColor': ((currentGuessNumber > r && showWordleColors) ? key[info[r]?.wordleInfo[l]]?.wordleColor?.hex : 'grey')}">
                <div [ngClass]="{'flipped': gameOver && (mode === 'daily' || mode === 'infinite' || mode === 'custom') && currentGuessNumber > r}">{{letter}}</div>
          </div>
          <div class="symbol-container">
            <div *ngFor="let status of info[r]?.symbolInfo; let s=index;"
                  [ngClass]="{'symbol': true, 'visible-symbol': info[r].revealed[s], 'invisible-symbol': !info[r].revealed[s]}">
              <img [ngStyle]="{'filter': key[status].symbolColor.filter}" [src]="key[status].symbol">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="mobileDesktopOrFullView === 'full'" class="full-ad-parent">
      <div #ad placementId="654b534496a6256ebdf129f3" class="ad-anchor"></div>
    </div>
</div>

<!-- message banner -->
<div *ngIf="gameOver && (mode === 'daily' || mode === 'infinite' || mode === 'custom')" class="game-info">
  {{winner ? "Well done! " : ""}}The answer was {{answer.toUpperCase()}}!
</div>

<div *ngIf="winner && mode === 'blitz'" class="game-info space-game-info">
  <span>Ans: {{answer.toUpperCase()}}</span><span>Solved: {{blitzFinishCount}}</span><span>Avg: {{roundedBlitzAvgScore || 'N/A'}}</span>
</div>
<div *ngIf="!winner && gameOver && mode === 'blitz'" class="game-info space-game-info">
  Too bad! The word was {{answer.toUpperCase()}}!
</div>

<div *ngIf="gameOver && mode === 'battle'" class="game-info">
  {{battleMessage}}
</div>

<div *ngIf="blitzStart" class="game-info space-game-info">
  <span class="blitz-timer">
    {{blitzDisplayTimer}}
  </span>
  <span [ngClass]="{'flash-score': flashBlitzScore, 'normal-score': !flashBlitzScore}">
    Score: {{blitzFinishCount}}
  </span>
  <span *ngIf="mode === 'battle'" [ngClass]="{'flash-opponent-score': flashOpponentScore, 'normal-score': !flashOpponentScore}">
    Opponent: {{opponentScore}}
  </span>
</div>

<div *ngIf="waitingForOpponent" class="game-info">
  <span class="glowing">Finding an opponent...</span>
</div>

<div *ngIf="opponentFound" class="game-info">
  <span class="glowing">Opponent Found! Get ready...</span>
</div>

<!-- keyboard -->
<div class="keyboard-container">
  <div *ngFor="let row of keyboard" class="keyboard-row-container">
    <div class="keyboard-row">
      <div *ngFor="let key of row"
            [ngClass]="{'selected': key.used && key.letter, 'keyboard-key': key.letter, 'spacer': !key.letter}"
            (click)="keyboardClicked(key)"
            [innerHtml]="key.letter || ''"></div>
    </div>
  </div>
</div>

<div class="ad-below-keyboard" #adContainer>
  <div #ad placementId="654b535396a6256ebdf129f5" class="ad-anchor" *ngIf="mobileDesktopOrFullView === 'desktop'"></div>
  <div #ad placementId="654b535396a6256ebdf129f5" class="ad-anchor" *ngIf="mobileDesktopOrFullView === 'full'"></div>
  <div #ad placementId="654b535d96a6256ebdf129f7" class="ad-anchor" *ngIf="mobileDesktopOrFullView === 'mobile'"></div>
</div>