<p>
  Getting a new device and want to keep your Symble statistics? Click the export button here to get some data copied to your clipboard.
  Save it somewhere (maybe email it to yourself?), and when you get your new device, open Symble, revisit this window, and click Import.
  Paste in the saved data, and your statistics should transfer over!
</p>
<div>
  <mat-radio-group [(ngModel)]="importExport">
    <mat-radio-button value="Export">Export</mat-radio-button>
    <mat-radio-button value="Import">Import</mat-radio-button>
  </mat-radio-group>
</div>
<br>
<mat-form-field style="width: 100%" *ngIf="importExport === 'Import'">
  <input matInput #input placeholder="Paste your Symble data to import" [(ngModel)]="importData" (ngModelChange)="isInputValid($event)">
  <mat-hint align="end" [ngStyle]="{'color': inputValid ? 'green' : 'red'}">{{inputValid ? 'Valid' : 'Invalid'}} Symble Data</mat-hint>
</mat-form-field>

<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false" class="give-up">Cancel</button>
  <button mat-stroked-button [disabled]="importExport === 'Import' && !inputValid" [mat-dialog-close]="{importExport, importData}" class="play-again">Confirm</button>
</mat-dialog-actions>
