<p>Create a custom Symble puzzle! It doesn't even have to be a real word, any 5
  letters will do. Play your custom puzzle to make sure it works as you hoped,
  and send the URL to a friend so they can try their luck!</p>

<mat-form-field style="width: 100%">
  <input matInput #input maxlength="5" placeholder="Create A Custom Symble" [(ngModel)]="customWord" (ngModelChange)="inputInvalid($event)">
  <mat-hint align="end" [ngStyle]="{'color': input.value?.length === 5 ? 'green' : 'red'}">{{input.value?.length || 0}}/5</mat-hint>
</mat-form-field>

<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false" class="give-up">Cancel</button>
  <button mat-stroked-button [mat-dialog-close]="customWord" [disabled]="invalid" class="play-again">Create URL!</button>
</mat-dialog-actions>
