export const symbolList = [
  'assets/symbols/light_mode.svg',//<span class="material-icons">light_mode</span>',
  'assets/symbols/dark_mode.svg',//'<span class="material-icons">dark_mode</span>',
  'assets/symbols/all_inclusive.svg',//'<span class="material-icons">all_inclusive</span>',
  'assets/symbols/gamepad.svg',//`<span class="material-icons">gamepad</span>`,
  'assets/symbols/pentagon.svg',//'<span class="material-icons">pentagon</span>',
  'assets/symbols/star.svg',//'<span class="material-icons">star</span>',
  'assets/symbols/trip_origin.svg',//'<span class="material-icons">trip_origin</span>',
  'assets/symbols/water_drop.svg',//'<span class="material-icons">water_drop</span>',
  'assets/symbols/token.svg',//'<span class="material-icons">token</span>',
  'assets/symbols/favorite.svg',//'<span class="material-icons">favorite</span>',
  'assets/symbols/whatshot.svg',//'<span class="material-icons">whatshot</span>'
];
