import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map, first } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class MultiplayerService {
  public disconnectSubject = new Subject<any>();

  constructor(private socket: Socket) {
    socket.disconnect();
    socket.on('disconnect', this.onDisconnect)
  }

  connect(connectParam: string = '') {
    this.socket.connect();
    this.socket.fromEvent('connected').pipe(first()).subscribe(() => {
      this.socket.emit('join-room', connectParam);
    })
  }

  disconnect() {
    this.socket.disconnect();
  }

  giveUp() {
    this.socket.emit('give-up');
  }

  onDisconnect = () => {
    this.disconnectSubject.next(null);
  }

  sendEvent(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  waitingForOpponent() {
    return this.socket.fromEvent('waiting-for-opponent');
  }

  opponentFound() {
    return this.socket.fromEvent('opponent-found');
  }

  opponentDisconnected() {
    return this.socket.fromEvent('opponent-disconnected');
  }

  gameStart() {
    return this.socket.fromEvent('start');
  }

  correct() {
    return this.socket.fromEvent('correct');
  }

  incorrect() {
    return this.socket.fromEvent('incorrect');
  }

  opponentCorrect() {
    return this.socket.fromEvent('opponent-correct');
  }

  win() {
    return this.socket.fromEvent('win');
  }

  lose() {
    return this.socket.fromEvent('lose');
  }

  tie() {
    return this.socket.fromEvent('tie');
  }

  timerSync() {
    return this.socket.fromEvent('timerSync');
  }
}
