<p>Add a custom room name to play against a friend! Or, leave it blank to play a random opponent!</p>

<mat-form-field style="width: 100%">
  <input matInput #input maxlength="20" placeholder="Create Or Join Custom Room" [(ngModel)]="roomName">
  <mat-hint align="end">{{input.value?.length || 0}}/20</mat-hint>
</mat-form-field>

<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false" class="give-up">Cancel</button>
  <button mat-stroked-button [mat-dialog-close]="roomName" class="play-again">Let's Battle!</button>
</mat-dialog-actions>
