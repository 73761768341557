<h2>What is Symble?</h2>
<p>Symble is a code-cracking logic game created by Daniel Baamonde. It was inspired by the viral
game Wordle which was created by Josh Wardle. The goal is to guess the secret 5-letter
word within 8 attempts. <a class="link-text" href="https://youtu.be/HaddTnpIdAY">Check out a video I made about the game
here</a> and then read the rest of this page to learn more!</p>

<p>There are 5 game modes to choose from using the first of the 3 icons
on the top right of the page.</p>

<p>
  <img class="menu-icon" src="assets/symbols/calendar_today_white.svg">
  In the Daily Puzzle everyone will have the same word, and it refreshes at midnight in your time zone!
</p>

<p>
  <img class="menu-icon" src="assets/symbols/all_inclusive_white.svg">
  Infinite mode lets you play as much as you like with random words every time
</p>

<p>
  <img class="menu-icon" src="assets/symbols/timer_white.svg">
  In 5 Minute Blitz it's you versus the clock. How many Symble puzzles you can solve in 5 minutes?
</p>

<p>
  <img class="menu-icon" src="assets/symbols/emoji_events_white.svg">
  Live Blitz Battle is the ultimate Symble challenge. Pit yourself against a random
  opponent or a friend of your choosing in a live 5 Minute Blitz competition! Ties for number of
  words solved are broken by average guesses. Become the best Symble player of all!
</p>

<p>
  <img class="menu-icon" src="assets/symbols/edit_white.svg">
  In Custom Puzzle mode, you can choose any 5 letters to be the answer of your very own Symble puzzle!
  It doesn't have to be a real word, and neither do the guesses when someone tries to solve it. When
  you create the puzzle, a special URL will be copied to your clipboard which includes your custom puzzle
  inside. Share it with your friends, and see if they can beat it!
</p>

<p>
  <img class="menu-icon" src="assets/symbols/sort_white.svg">
  Share your game results with friends and check your statistics from the menu found in this icon.
</p>

<p>
  <img class="menu-icon" src="assets/symbols/send_to_mobile_white.svg">
  This isn't a game mode, but if you want to transfer your game stats between devices, such as when you
  get a new phone, click here on the old device first!
</p>

<p>Despite the inspiration, Symble's strategy and gameplay is actually quite different from Wordle's.
Read on to learn how!</p>

<h2>The Rules</h2>

<p>Every game of Symble will include 3 randomly generated symbols, each meaning
something different. When you guess a word in Symble, you will be shown a sequence of 5 of those symbols
overlaid onto an invisible version of the secret answer, with each symbol corresponding to the
invisible letter directly underneath it. It bears repeating: the symbols line up with the
letters in the ANSWER, not the letters in your guess!</p>

<p>The following example explains each of the 3 concepts that the symbols can mean. Keep in mind
that the answer is visible over the symbols only for the sake of explanation, and would be invisible
during a real game. Let's say the answer to a Symble puzzle is SHAKE. You don't know this yet and
guess the word THICK. You are shown the 5 symbols in the below image.</p>

<img src="assets/symble-example.png" class="center">

<p>
  <img style="filter: invert(84%) sepia(86%) saturate(4599%) hue-rotate(105deg) brightness(100%) contrast(108%);" src="assets/symbols/gamepad.svg"> 
  in the 1st position corresponds to the S in SHAKE. It means "the letter I'm covering is not present in your guess", 
  because your guess of THICK did not contain an S. The same is true of the other two
  <img style="filter: invert(84%) sepia(86%) saturate(4599%) hue-rotate(105deg) brightness(100%) contrast(108%);" src="assets/symbols/gamepad.svg">s corresponding to A and E
  in the 3rd and 5th positions of SHAKE.
</p>

<p><img style="filter: invert(65%) sepia(39%) saturate(7482%) hue-rotate(86deg) brightness(121%) contrast(124%);" src="assets/symbols/all_inclusive.svg"> in the 2nd position corresponds
to the H in shake. It means "the letter I'm covering is present in your guess, and in the correct position",
because your guess of THICK correctly placed an H in the 2nd position as well.</p>

<p><img style="filter: invert(12%) sepia(97%) saturate(7487%) hue-rotate(2deg) brightness(104%) contrast(110%);" src="assets/symbols/token.svg"> in the 4th position corresponds to the K
in SHAKE. It means  "the letter I'm covering is present in your guess, but not in the correct position",
because your guess of THICK contained a K in the wrong place.</p>

<p>Of course when starting a Symble puzzle, you do not know which symbol means which concept, nor do you know
the invisible letters of the answer to which the symbols correspond. It is your job to apply your powers of
deduction to first figure out what each symbol means, and then use that information to discover the secret answer!</p>

<h2>Full Game Example</h2>

<p>Here is an example of a full game played from start to finish. Upon making your final guess, the grey tiles
of your guesses flip to show the colors they were meant to match to. If you prefer a guided walkthrough of a
full game instead, check out the video link at the top of this window!</p>

<img src="assets/symble-full-example.png" class="center">

<p>One situation worth highlighting is when the answer has a duplicate letter, such as the two O's in SHOOK above,
and your guess only has one of them, such as the 3rd guess: SOLID. If you only guess one letter of a pair (or triplet!),
there are two options for how that plays out.</p>

<p>If you guessed it in one of the correct places, you will get a "correct" symbol on the correct position,
and no information regarding the others of the same letter.</p>

<p>If you misplaced it, you will get a "misplaced" symbol on the earliest appearance of the letter
in the answer. This is why, when the O was misplaced in SOLID, the first of the two O's in SHOOK got the
blue droplet symbol indicating a misplaced letter.</p>

<p>Use this example to convince yourself of the rules, and then give it a shot!
Don't be discouraged if you find it difficult, and good luck!</p>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">Let's play!</button>
</mat-dialog-actions>
